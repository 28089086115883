const passwordRecovery = {
  en: {
    translation: {
      reset_password: "Reset password",
      check_your_email: "Check your email",
      email_sent_message:
        "If there is an account associated with this email address, you will receive an email shortly with instructions to reset your password.",
      email_not_sent: "I did not receive the email",
      email_not_sent_message:
        "If you do not receive the email immediately, please allow some time before making a new request, as there is a limit to the number of allowed requests",
      "Password reset failed": "The link you clicked is expired or invalid",
      "password must be at least 8 characters": "The password must be at least 8 characters long",
      "password must contain at least 1 letter and 1 number":
        "The password must contain at least 1 letter and 1 number",
    },
  },

  it: {
    translation: {
      reset_password: "Resetta password",
      check_your_email: "Controlla la tua email",
      email_sent_message:
        "Se un account registrato è associato a questo indirizzo email, riceverai a breve un messaggio con le istruzioni per reimpostare la password",
      email_not_sent: "Non ho ricevuto l'email",
      email_not_sent_message:
        "Se non ricevi l'email immediatamente, attendi un po' di tempo prima di fare una nuova richiesta, poiché c'è un limite al numero di richieste consentite",
      "Password reset failed": "Il link che hai visitato è scaduto o non valido",
      "password must be at least 8 characters":
        "La password deve avere una lunghezza di almeno 8 caratteri",
      "password must contain at least 1 letter and 1 number":
        "La password deve contenere almeno una lettera ed un numero",
    },
  },
};

export default passwordRecovery;
