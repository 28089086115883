// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import StealersStatistics from "layouts/dashboards/analytics/components/stealersStatistics";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import Alerts from "layouts/dashboards/analytics/components/alertsBox";
import countWeekPasswords from "api/statistics/countWeekPasswords";
// import getRegionCounter from "api/statistics/getRegionCounter";
import countDarkwebSitesIndexed from "api/statistics/countDarkwebSitesIndexed";
import countLastYearRansomware from "api/statistics/countLastYearRansomware";
// import getLatestReports from "api/statistics/getLatestReports";
import ServerError from "utilities/ServerError";

function Analytics() {
  // eslint-disable-next-line
  const [stolenCredentials, setStolenCredentials] = useState(0);
  // const [setRegionCounter] = useState([]);
  // const [latestReports, setLatestReports] = useState([]);
  const [stolenCredentialsPerWeek, setStolenCredentialsPerWeek] = useState(null);
  const [ransomwareCounter, setRansomwareCounter] = useState(0);
  const [ransomwareCountryCounter, setRansomwareCountryCounter] = useState(0);
  const [ransomwarePerMonth, setRansomwarePerMonth] = useState(null);
  const [sitesIndexed, setSitesIndexed] = useState(0);
  const [callsCount, setCallsCount] = useState(0);
  const [serverError, setServerError] = useState({
    credentials: false,
    ransomwares: false,
  });
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;
  const { t } = useTranslation();

  useEffect(() => {
    let count = callsCount;

    // Fetch all the data in parallel but process them in order
    const fetchData = async () => {
      const [lastYearRansomware, darkwebSitesIndexed, weekPasswords] = await Promise.allSettled([
        countLastYearRansomware(user?.country || "IT"),
        countDarkwebSitesIndexed(),
        countWeekPasswords(),
      ]);

      count += 1;
      if (lastYearRansomware.status === "fulfilled") {
        const res = lastYearRansomware.value;
        setRansomwareCounter(res.counter);
        setRansomwareCountryCounter(res.counterCountry);
        setRansomwarePerMonth(res.arrayCounter);
      } else {
        setServerError({ ...serverError, ransomwares: true });
        console.log("error while getting ransomwares", lastYearRansomware.reason);
      }

      count += 1;
      if (weekPasswords.status === "fulfilled") {
        const res = weekPasswords.value;
        setStolenCredentials(String(res.counter).replace(/(.)(?=(\d{3})+$)/g, "$1."));
        setStolenCredentialsPerWeek(res.arrayCounter);
      } else {
        setServerError({ ...serverError, credentials: true });
        console.log("error while getting count week passwords", weekPasswords.reason);
      }

      count += 1;
      if (darkwebSitesIndexed.status === "fulfilled") {
        const res = darkwebSitesIndexed.value;
        if (res.counter) setSitesIndexed(String(res.counter).replace(/(.)(?=(\d{3})+$)/g, "$1."));
        else setSitesIndexed("N/A");
      } else {
        setServerError({ ...serverError, credentials: true });
        console.log("error while counting stolen credentials", darkwebSitesIndexed.reason);
      }

      setCallsCount(count);
    };

    fetchData()
      .then()
      .catch((err) => console.error(err));
  }, []);

  let dataToDays = null;
  // let dataToHours = null;
  let dataToMonths = null;

  if (stolenCredentialsPerWeek && stolenCredentialsPerWeek.length > 0) {
    const labelsGiorno = [
      { label: t("statistics.monday_short"), day: 1 },
      { label: t("statistics.tuesday_short"), day: 2 },
      { label: t("statistics.wednesday_short"), day: 3 },
      { label: t("statistics.thursday_short"), day: 4 },
      { label: t("statistics.friday_short"), day: 5 },
      { label: t("statistics.saturday_short"), day: 6 },
      { label: t("statistics.sunday_short"), day: 0 },
    ];
    const labelsGiornoFinal = [];
    stolenCredentialsPerWeek.forEach((giorno) => {
      const dayToFindIndex = labelsGiorno.findIndex((day) => day.day === giorno.label);
      if (dayToFindIndex >= 0) {
        labelsGiornoFinal.push(labelsGiorno[dayToFindIndex].label);
      }
    });
    const valuesGiornoFinal = stolenCredentialsPerWeek.map((el) => el.value);
    dataToDays = {
      labels: labelsGiornoFinal,
      datasets: {
        label: t("statistics.attacks"),
        data: valuesGiornoFinal,
      },
    };
  }

  if (ransomwarePerMonth && ransomwarePerMonth.length > 0) {
    const labelsGiorno = [
      { label: t("statistics.january_short"), value: 1 },
      { label: t("statistics.february_short"), value: 2 },
      { label: t("statistics.march_short"), value: 3 },
      { label: t("statistics.april_short"), value: 4 },
      { label: t("statistics.may_short"), value: 5 },
      { label: t("statistics.june_short"), value: 6 },
      { label: t("statistics.july_short"), value: 7 },
      { label: t("statistics.august_short"), value: 8 },
      { label: t("statistics.september_short"), value: 9 },
      { label: t("statistics.october_short"), value: 10 },
      { label: t("statistics.november_short"), value: 11 },
      { label: t("statistics.december_short"), value: 12 },
    ];
    const labelsGiornoFinal = [];
    ransomwarePerMonth.forEach((month) => {
      const dayToFindIndex = labelsGiorno.findIndex((day) => day.value === month.label);
      if (dayToFindIndex >= 0) {
        labelsGiornoFinal.push(labelsGiorno[dayToFindIndex].label);
      }
    });
    const valuesGiornoFinal = ransomwarePerMonth.map((el) => el.value);
    dataToMonths = {
      labels: labelsGiornoFinal,
      datasets: {
        label: t("statistics.attacks"),
        data: valuesGiornoFinal,
      },
    };
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {callsCount >= 2 ? (
        <MDBox>
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={7}>
                <Grid
                  container
                  className="boxes-border-radius"
                  sx={{
                    background: "white",
                    minHeight: "290.5px",
                    boxShadow:
                      "0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%)",
                    alignContent: "flex-start",
                  }}
                >
                  <MDBox
                    className="home-box-header"
                    sx={{
                      width: "100%",
                      background: "#2C74E1",
                      maxHeight: "48.5px",
                    }}
                  >
                    <MDTypography variant="header3" color="light" fontWeight="bold">
                      {t("analytics.your_overview")}
                    </MDTypography>
                  </MDBox>
                  <StealersStatistics
                    columns={6}
                    boxShadow="none"
                    border="1px solid rgba(0,0,0,0.1)"
                    padding="0px"
                  />
                </Grid>
              </Grid>
              <Alerts />
              <Grid item sx={{ flexGrow: "1" }}>
                <Grid
                  item
                  container
                  sm={12}
                  md={12}
                  lg={12}
                  className="boxes-border-radius"
                  style={{
                    background: "white",
                    boxShadow:
                      "0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%)",
                    alignItems: "flex-start",
                  }}
                >
                  <MDBox className="home-box-header" width="100%" sx={{ background: "#2C74E1" }}>
                    <MDTypography variant="header3" color="white" fontWeight="bold">
                      {t("analytics.global_overview")}
                    </MDTypography>
                  </MDBox>
                  {stolenCredentials && dataToDays && sitesIndexed && sitesIndexed.length > 0 ? (
                    <Grid
                      item
                      container
                      display="flex"
                      justifyContent="space-between"
                      sx={{ height: "370px" }}
                      md={6}
                      lg={6}
                    >
                      {!serverError.credentials ? (
                        <>
                          <MDBox
                            className="dashboard-credentials-boxes"
                            mb={2}
                            mr={1}
                            pl={4}
                            pt={2}
                            pb={2}
                          >
                            <MDTypography variant="h6" color="dark" fontWeight="bold" mb={1}>
                              {t("analytics.title_credentials")}
                            </MDTypography>
                            <MDTypography
                              variant="h3"
                              fontSize="1.5em"
                              color="dark"
                              fontWeight="bold"
                              sx={{ lineHeight: "1" }}
                            >
                              {stolenCredentials}
                            </MDTypography>
                            <MDTypography
                              component="div"
                              variant="caption"
                              fontSize="small"
                              fontWeight="regular"
                              sx={{ color: "#669FF6" }}
                            >
                              {t("analytics.last_two_weeks")}
                            </MDTypography>
                          </MDBox>
                          <MDBox
                            className="dashboard-credentials-boxes"
                            mb={2}
                            ml={1}
                            pl={4}
                            pt={2}
                            pb={2}
                          >
                            <MDTypography variant="h6" color="dark" fontWeight="bold" mb={1}>
                              {t("analytics.title_darkweb_sites")}
                            </MDTypography>
                            <MDTypography
                              variant="h3"
                              fontSize="1.5em"
                              color="dark"
                              fontWeight="bold"
                              sx={{ lineHeight: "1" }}
                            >
                              {sitesIndexed}
                            </MDTypography>
                            <MDTypography
                              component="div"
                              variant="caption"
                              fontSize="small"
                              fontWeight="regular"
                              sx={{ color: "#669FF6" }}
                            >
                              {t("analytics.last_24h")}
                            </MDTypography>
                          </MDBox>
                          <MDBox width="100%">
                            <ReportsLineChart
                              color="dark"
                              title={t("statistics.attacks_per_hour")}
                              chart={dataToDays}
                            />
                          </MDBox>
                        </>
                      ) : (
                        <MDBox className="server-error-container">
                          <ServerError />
                        </MDBox>
                      )}
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    container
                    id="global-box-line"
                    lg={0.5}
                    justifyContent="center"
                    alignSelf="center"
                  >
                    <hr id="home-global-line-divider-one" />
                  </Grid>
                  <hr id="home-global-line-divider-two" />
                  <Grid item xs={12} md={12} lg={5} style={{ flexGrow: "1" }}>
                    {!serverError.ransomwares ? (
                      <>
                        {dataToMonths && ransomwareCounter ? (
                          <ReportsBarChart
                            color="success"
                            ransomwareCounter={ransomwareCounter}
                            ransomwareCountryCounter={ransomwareCountryCounter}
                            country="IT"
                            title={t("statistics.attacks_per_month")}
                            chart={dataToMonths}
                          />
                        ) : (
                          <div className="loader" />
                        )}
                      </>
                    ) : (
                      <MDBox className="server-error-container">
                        <ServerError />
                      </MDBox>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      ) : (
        <div className="loader home" />
      )}
    </DashboardLayout>
  );
}

export default Analytics;
