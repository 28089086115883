// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MDInput from "components/MDInput";
import InfoIcon from "@mui/icons-material/Info";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import CookieIcon from "@mui/icons-material/Cookie";
import PasswordIcon from "@mui/icons-material/Password";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { getHostFromUrl } from "utilities/StealerUrls";
import moment from "moment";

function TableContentFull({ elements, type }) {
  const [isOpen, setIsOpen] = useState(true);
  const [filterDomain, setFilterDomain] = useState("");
  const [filterName, setFilterName] = useState("");
  const [elementsToView, setElementsToView] = useState([]);
  const { t } = useTranslation();

  function setContent() {
    const elementsCopy = [].concat(elements);
    const realElements = [];
    if (type === "passwords") {
      elementsCopy.forEach((item) => {
        const show = !filterDomain || item.URL?.toLowerCase().includes(filterDomain.toLowerCase());
        if (show) {
          realElements.push({
            label: item.username,
            value: item.password ? item.password : t("stealers.not_available"),
            domain: item.URL,
            length: item.length || null,
            isPasswordDoubtful: item.isPasswordDoubtful || false,
            strength: item.strength
              ? t(`password_strength.${item.strength}`)
              : t("stealers.not_available"),
          });
        }
      });
    } else {
      elementsCopy.forEach((item) => {
        if (filterDomain || filterName) {
          if (
            item.domain.toLowerCase().includes(filterDomain.toLowerCase()) &&
            item.name.toLowerCase().includes(filterName.toLowerCase())
          ) {
            realElements.push({
              domain: item.domain,
              value: item.value,
              label: item.name,
              expiration: item.expiration,
            });
          }
        } else {
          realElements.push({
            domain: item.domain,
            value: item.value,
            label: item.name,
            expiration: item.expiration,
          });
        }
      });
    }
    setElementsToView(realElements);
  }

  useEffect(() => {
    setContent();
  }, [elements]);

  useEffect(() => {
    setContent();
  }, [filterDomain, filterName]);

  function getPassword(element) {
    if (element) {
      if (element.value?.length > 0 && element.value !== t("stealers.not_available"))
        return element.value;
      if (element.length) {
        if (element.isPasswordDoubtful) {
          return (
            <Tooltip title={t("stealers.password_doubtful")} placement="top">
              <span style={{ opacity: "0.5" }}>{`${element.length} ${t(
                "stealers.characters_length"
              )}`}</span>
            </Tooltip>
          );
        }
        return `${element.length} ${t("stealers.characters_length")}`;
      }
    }

    return t("stealers.not_detected");
  }

  const getExpirationStatus = (date) => {
    if (!date) {
      return (
        <td>
          <p style={{ display: "inline-block" }}>{t("stealers.not_available_short")}</p>
        </td>
      );
    }

    // Get a Moment.js object
    const expirationDate = moment.unix(date).format("YYYY-MM-DD");
    const isExpired = moment().isSameOrAfter(expirationDate);
    return (
      <td>
        {isExpired ? (
          <p style={{ display: "inline-block" }}>{t("stealers.expired")}</p>
        ) : (
          <p style={{ display: "inline-block" }}>{t("stealers.active")}</p>
        )}
        <Tooltip title={`${t("stealers.expiration_date")}: ${expirationDate}`}>
          <InfoIcon style={{ verticalAlign: "middle", marginLeft: "5px" }} />
        </Tooltip>
      </td>
    );
  };

  function getContent(contents) {
    return contents.map((elem, index) => (
      <tr key={`${type}-for-client-${index.toString()}`}>
        {/* className={`detailsStealerTableRow ${index % 2 === 0 ? "table-even-row" : ""}`} */}
        <td>
          <Tooltip title={`${t("copy")} (click)`} placement="top">
            <Button
              className="stealers-details-domains-copy-btn"
              style={{
                width: "100%",
                color: "#344767",
                fontWeight: "400",
                textTransform: "none",
                fontSize: "14px",
                padding: "0px",
                justifyContent: "flex-start",
              }}
              variant="text"
              onClick={() => {
                navigator.clipboard.writeText(elem.domain);
              }}
            >
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {getHostFromUrl(elem.domain)}
              </span>
            </Button>
          </Tooltip>
        </td>
        <td>{elem.label}</td>
        <td>
          {type === "passwords" ? (
            <>
              <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                {getPassword(elem)}
                {elem.alreadySeen ? (
                  <Tooltip title={`${t("stealers.password_already_seen")} ${elem.alreadySeen}`}>
                    <Icon fontSize="small" color="secondary">
                      visibility
                    </Icon>
                  </Tooltip>
                ) : null}
                {elem.isCombolist ? (
                  <Tooltip title={`${t("stealers.password_reuse")}`}>
                    <Icon fontSize="small" color="primary">
                      warning
                    </Icon>
                  </Tooltip>
                ) : null}
              </div>
            </>
          ) : (
            <div className="details-stealer-cookie-value">
              <div className="label">{elem.value}</div>
              <div className="icon">
                <Tooltip title={t("copy")} placement="top">
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(elem.value);
                    }}
                    size="small"
                    disableRipple
                  >
                    <Icon fontSize="medium">copy</Icon>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )}
        </td>
        {/* eslint-disable-next-line */}
        {type === "passwords" ? (
          elem.strength ? (
            <td>
              {elem.isPasswordDoubtful ? (
                <div>
                  <Tooltip title={t("stealers.password_doubtful")} placement="top">
                    <span style={{ opacity: "0.5" }}>{elem.strength}</span>
                  </Tooltip>
                </div>
              ) : (
                elem.strength
              )}
            </td>
          ) : (
            <td>{t("stealers.not_detected")}</td>
          )
        ) : null}
        {type === "cookies" ? getExpirationStatus(elem.expiration) : null}
      </tr>
    ));
  }

  return (
    <div className="details-stealer-table-container">
      <div className="popupLabel">
        <IconButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          style={{ color: "#344767" }}
          size="small"
          disableRipple
        >
          {type === "passwords" ? (
            <PasswordIcon fontSize="medium" style={{ marginRight: "10px" }} />
          ) : (
            <CookieIcon fontSize="medium" style={{ marginRight: "10px" }} />
          )}
          <span className="details-stealer-table-title">
            {type === "passwords" ? "Password:" : "Cookies:"}
          </span>
          {isOpen ? <ArrowDropUpIcon fontSize="medium" /> : <ArrowDropDownIcon fontSize="medium" />}
        </IconButton>
      </div>
      {isOpen ? (
        <>
          <span>
            <MDInput
              type="input"
              label="dominio"
              value={filterDomain}
              onChange={(e) => {
                setFilterDomain(e.target.value);
              }}
              style={{ marginBottom: "10px", marginRight: "10px" }}
            />
          </span>
          <span style={{ border: "none", marginBottom: "10px" }}>
            {type === "cookies" ? (
              <MDInput
                type="input"
                label="nome"
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                }}
                style={{ marginBottom: "10px" }}
              />
            ) : null}
          </span>
          <div
            className="table_users_list"
            style={type === "passwords" ? { marginBottom: "50px" } : {}}
          >
            <span className="elem-cell" />
            <table className="table-content" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th style={type !== "passwords" ? { width: "20%" } : null}>
                    {type === "passwords" ? t("stealers.domain") : t("stealers.domain")}
                  </th>
                  <th style={type !== "passwords" ? { width: "20%" } : null}>
                    {type === "passwords" ? t("stealers.login") : t("stealers.cookie_name")}
                  </th>
                  <th>
                    {type === "passwords" ? (
                      <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                        {t("stealers.password")}
                        <Tooltip
                          title={
                            <div style={{ fontSize: "small", textAlign: "left" }}>
                              <p style={{ fontWeight: "600" }}>
                                {t("stealers.info_stealer_password_tooltip_title")}
                              </p>
                              <p style={{ paddingBottom: "15px" }}>
                                {t("stealers.info_stealer_password_tooltip_text")}
                              </p>

                              <p style={{ fontWeight: "600" }}>
                                {t("stealers.combolist_password_tooltip_title")}
                              </p>
                              <p>{t("stealers.combolist_password_tooltip_text")}</p>
                            </div>
                          }
                          style={{ fontSize: "10px", textDecoration: "underline" }}
                        >
                          <Icon style={{ fontSize: "small" }}>help_outline</Icon>
                        </Tooltip>
                      </div>
                    ) : (
                      t("stealers.cookie_value")
                    )}
                  </th>
                  {type === "passwords" ? <th>{t("stealers.password_strength")}</th> : null}
                  {type === "cookies" ? <th>{t("stealers.status")}</th> : null}
                </tr>
              </thead>
              <tbody>
                {elementsToView && elementsToView.length > 0 ? getContent(elementsToView) : null}
              </tbody>
            </table>
          </div>
        </>
      ) : null}
    </div>
  );
}

// Typechecking props for the TableContentFull
TableContentFull.propTypes = {
  // eslint-disable-next-line
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string,
};

TableContentFull.defaultProps = {
  type: "password",
};

export default TableContentFull;
