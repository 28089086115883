import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
// import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import getRequests from "api/reportsrequests/getRequests";
import print from "api/print/print";
import getType from "utilities/getType";
import Pagination from "layouts/dashboards/stealers/pagination";

const Reports = () => {
  const [requests, setRequests] = useState([]);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [limit, setLimit] = useState(10);

  const { t } = useTranslation();

  function refreshReports() {
    getRequests(page, limit)
      .then((res) => {
        if (res && res.results) {
          // console.log("res", res.results);
          setPages(res.totalPages);
          setRequests(res.results);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error while getting reports requests", err);
      });
  }

  useEffect(() => {
    refreshReports();
  }, [page, limit]);

  // De-comment the lines below in order to use the "user" variable to check if is admin or not
  const [controller] = useMaterialUIController();
  const { user } = controller;

  const supportEmail = "info@defenda.io";

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2 className="page-title" style={{ marginBottom: "50px" }}>
        Reports
      </h2>
      {requests && requests.length > 0 ? (
        <>
          <div className="reports-page-description-box">
            <p>{t("reports.text_box_personalized_reports")}</p>
            <p style={{ marginTop: "7px" }}>
              {t("reports.text_box_contact_us")}
              <span style={{ fontWeight: "600" }}>
                <a href={`mailto:${supportEmail}`} style={{ color: "var(--blue-color)" }}>
                  {supportEmail}
                </a>
              </span>
            </p>
          </div>
          <div className="table_users_list">
            <table className="table-content" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>{t("reports.date")}</th>
                  <th style={{ textAlign: "center" }}>{t("reports.type")}</th>
                  {user && user.role && user.role === "admin" ? (
                    <th style={{ textAlign: "center" }}>{t("reports.user")}</th>
                  ) : null}
                  <th style={{ textAlign: "center" }}>Report pdf</th>
                  <th style={{ textAlign: "center" }}>{t("reports.details")}</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((req, index) => (
                  <tr key={index.toString()} style={{ textAlign: "center" }}>
                    <td>{req.date ? moment(req.date).format("DD/MM/YYYY - HH:mm:ss") : null}</td>
                    <td>{req.stealer ? getType(req.stealer) : ""}</td>
                    {user && user.role && user.role === "admin" && req.user && req.user.name ? (
                      <td>
                        {req.user.name}
                        {req.user &&
                        req.user.clients &&
                        req.user.clients[0] &&
                        req.user.clients[0].name
                          ? ` (${req.user.clients[0].name})`
                          : ""}
                      </td>
                    ) : null}
                    <td>
                      {req.status && req.status === "fulfilled" && req.pathS3 && req.indexKey ? (
                        <button
                          type="button"
                          onClick={() => {
                            print(req.pathS3.replaceAll("/", "_"), req.indexKey)
                              .then((res) => res.blob())
                              .then((blob) => {
                                const fileURL = URL.createObjectURL(blob);
                                // Open the URL on new Window
                                const pdfWindow = window.open();
                                pdfWindow.location.href = fileURL;
                                // IF WE WANT TO DOWNLOAD THE FILE INSTEAD OF OPENING IT IN NEW TAB
                                // const url = window.URL.createObjectURL(blob);
                                // const a = document.createElement("a");
                                // a.style.display = "none";
                                // a.href = url;
                                // // the filename you want
                                // a.download = "report-pdf";
                                // document.body.appendChild(a);
                                // a.click();
                                // document.body.removeChild(a);
                                // window.URL.revokeObjectURL(url);
                              })
                              .catch((err) => {
                                // eslint-disable-next-line
                                console.log("errore frontend print", err);
                              });
                          }}
                          style={{
                            border: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 auto",
                          }}
                          className="download-button-link"
                          size="small"
                        >
                          <PictureAsPdfIcon />
                          <span className="download-button-link-label">Download</span>
                        </button>
                      ) : (
                        t("reports.pending")
                      )}
                    </td>
                    <td>
                      {req.stealer && req.stealer.id ? (
                        <button
                          type="button"
                          style={{ border: "none" }}
                          onClick={() => {
                            window.open(`/detailsStealer?${req.stealer.id}`, "_blank").focus();
                          }}
                          className="download-button-link-details"
                          size="small"
                        >
                          {t("reports.show")}
                        </button>
                      ) : (
                        t("reports.no_stealer")
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {pages && pages > 1 ? (
              <Pagination setPage={setPage} pages={pages} active={page} />
            ) : null}
          </div>
        </>
      ) : (
        <>
          <div className="reports-page-description-box">
            <p style={{ fontSize: "small" }}>{t("reports.text_box_no_reports")}</p>
            <p style={{ fontWeight: "600" }}>{t("reports.text_box_contact_us")}</p>
          </div>
          <div>{t("reports.no_reports_yet")}</div>
        </>
      )}
    </DashboardLayout>
  );
};

export default Reports;
