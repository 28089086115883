/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Ransomwares from "layouts/dashboards/ransomwares";
import Stealers from "layouts/dashboards/stealers";
import AlertsPage from "layouts/dashboards/alertsPage";
import SignInBasic from "layouts/authentication/sign-in/basic";
import ResetPassword from "layouts/authentication/reset-password";
import ForgotPassword from "layouts/authentication/forgot-password";
import Mfa from "layouts/authentication/mfa";
import ConfirmEmail from "layouts/authentication/confirm-email";
import DetailsStealer from "layouts/dashboards/detailsStealer";
import DetailsLensPhishing from "layouts/dashboards/detailLensPhishing";
import DetailStatisticsNations from "layouts/dashboards/detailStatisticsNations";
import Reports from "layouts/dashboards/reports";
import User from "layouts/dashboards/user";
import Sellers from "layouts/backoffice/sellers";
import DarkWebMonitoring from "layouts/dashboards/darkWebMonitoring";
import DataBreach from "layouts/dashboards/dataBreach";
import DarkWebDetails from "layouts/dashboards/darkWebMonitoring/detailsPage";
import DataBreachDetails from "layouts/dashboards/dataBreach/detailsPage";
// admin routes
import UserList from "layouts/backoffice/userlist/index";
import AddUser from "layouts/backoffice/adduser/index";
import AddClient from "layouts/backoffice/addClient/index";
import ClientList from "layouts/backoffice/clientList/index";
import Codes from "layouts/backoffice/codes/index";
import Messages from "layouts/backoffice/messages";

// Material Dashboard 2 PRO React components
// @mui icons
import Icon from "@mui/material/Icon";
import Phishing from "layouts/dashboards/phishings";
import PhishingDetails from "layouts/dashboards/phishings/detailsPage";

const routes = [];

routes.push({
  type: "collapse",
  name: "dashboard",
  key: "dashboard",
  icon: <Icon fontSize="medium">dashboard</Icon>,
  route: "/dashboard",
  component: <Analytics />,
});

routes.push({
  type: "divider",
});

routes.push({
  type: "collapse",
  name: "passwords",
  key: "passwords",
  icon: <Icon fontSize="medium">password</Icon>,
  route: "/identity",
  component: <Stealers />,
});

routes.push({
  type: "collapse",
  name: "data_breach",
  key: "data_breach",
  icon: <Icon fontSize="medium">security_update_warning_icon</Icon>,
  route: "/databreaches",
  component: <DataBreach />,
  services: ["databreach"],
});

routes.push({
  name: "data_breach_details",
  key: "data_breach_details",
  route: "/dataBreaches/:id",
  component: <DataBreachDetails />,
  services: ["databreach"],
});

routes.push({
  type: "collapse",
  name: "phishing",
  key: "phishing",
  icon: <Icon fontSize="medium">warning_amber</Icon>,
  route: "/phishing",
  component: <Phishing />,
  services: ["phishing"],
});

routes.push({
  name: "phishing_details",
  key: "phishing_details",
  route: "/phishing/:id",
  component: <PhishingDetails />,
  services: ["phishing"],
});

routes.push({
  type: "collapse",
  name: "dark_web",
  key: "dark_web",
  icon: <Icon fontSize="medium">vpn_lock</Icon>,
  route: "/darkweb",
  component: <DarkWebMonitoring />,
  services: ["darkwebmonitoring"],
});

routes.push({
  type: "divider",
  services: ["darkwebmonitoring"],
});

routes.push({
  name: "dark_web_details",
  key: "dark_web_details",
  route: "/darkweb/threat/:id",
  component: <DarkWebDetails />,
  services: ["darkwebmonitoring"],
});

routes.push({
  type: "collapse",
  name: "ransomware",
  key: "ransomware",
  icon: <Icon fontSize="medium">warning</Icon>,
  route: "/ransomwares",
  component: <Ransomwares />,
});

routes.push({
  type: "divider",
});

routes.push({
  type: "collapse",
  name: "alerts",
  key: "alerts",
  icon: <Icon fontSize="medium">notifications</Icon>,
  route: "/alerts",
  component: <AlertsPage />,
});

routes.push({
  type: "collapse",
  name: "reports",
  key: "reports",
  icon: <Icon fontSize="medium">assessment</Icon>,
  route: "/reports",
  component: <Reports />,
});

routes.push({
  type: "collapse",
  name: "user",
  key: "user",
  icon: <Icon fontSize="medium">person</Icon>,
  route: "/user",
  component: <User />,
});

routes.push({
  type: "collapse",
  name: "sellers",
  key: "sellers",
  icon: <Icon fontSize="medium">sell</Icon>,
  route: "/sellers",
  component: <Sellers />,
  roles: ["admin", "administration"],
});

routes.push(
  {
    name: "Login",
    key: "login",
    route: "/login",
    component: <SignInBasic />,
  },
  {
    name: "ResetPassword",
    key: "resetPassword",
    route: "/resetPassword",
    component: <ResetPassword />,
  },
  {
    name: "ForgotPassword",
    key: "forgotPassword",
    route: "/forgotPassword",
    component: <ForgotPassword />,
  },
  {
    name: "mfa",
    key: "mfa",
    route: "/mfa",
    component: <Mfa />,
  },
  {
    name: "DetailsStealer",
    key: "detailsStealer",
    route: "/detailsStealer",
    component: <DetailsStealer />,
  },
  {
    name: "DetailsLensPhishing",
    key: "detailsLensPhishing",
    route: "/detailsLensPhishing",
    component: <DetailsLensPhishing />,
  },
  {
    name: "detailStatisticsNations",
    key: "detailStatisticsNations",
    route: "/detailStatisticsNations",
    component: <DetailStatisticsNations />,
  },
  {
    name: "ConfirmEmail",
    key: "confirmEmail",
    route: "/confirmEmail",
    component: <ConfirmEmail />,
  }
);

// admin routes
routes.push(
  {
    type: "collapse",
    name: "messages",
    key: "messages",
    route: "/messages",
    component: <Messages />,
    roles: ["admin"],
  },
  {
    type: "collapse",
    name: "userlist",
    key: "userlist",
    route: "/userlist",
    component: <UserList />,
    roles: ["admin"],
  },
  {
    type: "collapse",
    name: "add_user",
    key: "addUser",
    route: "/adduser",
    component: <AddUser />,
    roles: ["admin"],
  },
  {
    type: "collapse",
    name: "customers_list",
    key: "clientList",
    route: "/clientlist",
    component: <ClientList />,
    roles: ["admin"],
  },
  {
    type: "collapse",
    name: "add_customer",
    key: "addClient",
    route: "/addclient",
    component: <AddClient />,
    roles: ["admin"],
  },
  {
    type: "collapse",
    name: "codes",
    key: "codes",
    route: "/codes",
    component: <Codes />,
    roles: ["admin"],
  }
);

export default routes;
