import config from "config/config";
import getTokens from "api/getTokens";

const { baseUrl, clientUrl } = config;

/**
 * @typedef {Object} AvailableService
 * @property {string} code Service code
 * @property {string} description Service string
 */

/**
 * Returns the list of available services
 * @returns {AvailableService[]} Available services
 */
export default function getAvailableServices() {
  const endpoint = `${baseUrl}/v1/services`;
  const tokens = getTokens();

  const access = tokens?.access?.token;
  if (!access) {
    window.location.href = "/login";
  }

  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl || ""}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
  };
  // console.log('login endpoint', {endpoint, BODY });
  return fetch(endpoint, options)
    .then((response) => {
      // console.log('login response', response);
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error();
      }
      return response.json();
    })
    .catch((e) => {
      console.log("error", e);
    });
}
