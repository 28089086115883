// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";
// import getTokens from "api/getTokens";
import getAllUser from "api/users/getAllUser";
import updateExpiration from "api/clients/updateExpiration";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
import IconButton from "@mui/material/IconButton";
import { useMaterialUIController } from "context";
import Pagination from "layouts/dashboards/stealers/pagination";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Icons import

function UserList() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const [users, setUsers] = useState([]);
  const [emailFilter, setEmailFilter] = useState("");
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [orderBy, setOrderBy] = useState("email");
  const [editingExpiration, setEditingExpiration] = useState(false);
  const [popupEditSuccess, setPopupEditSuccess] = useState(false);
  const [popupEditFail, setPopupEditFail] = useState(false);
  const [newExpiration, setNewExpiration] = useState("");
  const [elementView, setElementView] = useState(null);
  const { t } = useTranslation();

  function getUsers() {
    getAllUser(page, limit, emailFilter, "seller", false, orderBy)
      .then((res) => {
        // console.log("SELLERS", res);
        if (res.results && res.results.length > 0) {
          if (res.totalPages) {
            setPages(res.totalPages);
          }
          setUsers(res.results);
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }

  function getCalendar() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ marginBottom: "30px" }}>
          <Datetime
            inputProps={{
              placeholder: t("admin.new_expiration"),
              value: newExpiration,
            }}
            open
            timeFormat={false}
            value={newExpiration}
            onChange={(e) => {
              setNewExpiration(moment(e).format("L"));
            }}
          />
        </div>
      </div>
    );
  }

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [page, limit, emailFilter, orderBy]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {elementView && elementView.id ? (
        <SweetAlert
          style={{ display: "block" }}
          title={elementView.name || ""}
          onConfirm={() => {
            setElementView(null);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {elementView?.vatId ? (
            <div>
              {t("user.vat_id")}: <span style={{ fontWeight: "bold" }}>{elementView.vatId}</span>
            </div>
          ) : null}
          {elementView?.serviceName ? (
            <div>
              {t("user.service")}:{" "}
              <span style={{ fontWeight: "bold" }}>{elementView.serviceName}</span>
            </div>
          ) : null}
          {elementView?.contractStart ? (
            <div>
              {t("user.contract_start")}:{" "}
              <span style={{ fontWeight: "bold" }}>
                {moment(elementView.contractStart).format("DD/MM/YYYY")}
              </span>
            </div>
          ) : null}
          {elementView?.contractExpiration ? (
            <div>
              {t("user.contract_expiration")}:
              <span style={{ fontWeight: "bold" }}>
                {moment(elementView.contractExpiration).format("DD/MM/YYYY")}
              </span>
              {moment(elementView.contractExpiration).isBefore(moment()) ? (
                <span style={{ color: "var(--red-color)" }}> ({t("user.expired")}) </span>
              ) : null}
            </div>
          ) : null}
          <IconButton
            onClick={() => {
              setEditingExpiration(true);
            }}
            size="small"
            disableRipple
          >
            {t("admin.edit_expiration")}
          </IconButton>
          {/* Se c'è possiamo farla modificare, facciamo una chiamata dove mandiamo id utente e nuova data
          in backend aggiorniamo la data di scadenza del contratto e la data all'interno dei serviceDetail
          si potrebbe chiedere anche una conferma prima di eseguire */}
        </SweetAlert>
      ) : null}
      {editingExpiration ? (
        <div className="editExpirationPopup">
          <SweetAlert
            style={{ display: "block", minHeight: "550px" }}
            title={t("admin.edit_expiration")}
            onConfirm={() => {
              // cambio scadenza
              updateExpiration(elementView.id, newExpiration)
                .then((res) => {
                  if (res && res.newClient && res.newClient.id) {
                    setEditingExpiration(false);
                    setElementView(null);
                    setPopupEditSuccess(true);
                    getUsers();
                  } else {
                    setEditingExpiration(false);
                    setElementView(null);
                    setPopupEditFail(true);
                    getUsers();
                  }
                })
                .catch((err) => {
                  // eslint-disable-next-line
                  console.log("error while updating expiration", err);
                  // TODO set error
                });
              // chiusura popup
              // setElementView(null);
              // cancello valore campo
              setNewExpiration("");
            }}
            confirmBtnText="Ok"
            confirmBtnCssClass="popupCloseButton"
            confirmBtnStyle={{
              boxShadow: "none",
            }}
          >
            {getCalendar()}
          </SweetAlert>
        </div>
      ) : null}
      {popupEditSuccess ? (
        <div className="editExpirationPopup">
          <SweetAlert
            style={{ display: "block" }}
            title={t("admin.client_expiration_edit_success_title")}
            success
            onConfirm={() => {
              setPopupEditSuccess(false);
            }}
            confirmBtnText="Ok"
            confirmBtnCssClass="popupCloseButton"
            confirmBtnStyle={{
              boxShadow: "none",
            }}
          >
            {t("admin.client_expiration_edit_success_text")}
          </SweetAlert>
        </div>
      ) : null}
      {popupEditFail ? (
        <div className="editExpirationPopup">
          <SweetAlert
            style={{ display: "block" }}
            title={t("admin.client_expiration_edit_fail_title")}
            error
            onConfirm={() => {
              setPopupEditFail(false);
            }}
            confirmBtnText="Ok"
            confirmBtnCssClass="popupCloseButton"
            confirmBtnStyle={{
              boxShadow: "none",
            }}
          >
            {t("admin.client_expiration_edit_fail_text")}
          </SweetAlert>
        </div>
      ) : null}
      <div style={{ display: "flex", marginTop: "30px", justifyContent: "space-between" }}>
        <div style={{ lineHeight: "40px", marginRight: "15px" }}>
          {t("displayed")}:
          <select
            onChange={(e) => {
              setLimit(e.target.value);
            }}
            value={limit}
            className="selectDefault"
            style={{
              padding: "0.75rem",
              borderRadius: "0.375rem",
              borderColor: "#d2d6da",
              marginBottom: "30px",
              marginLeft: "25px",
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
      {users && users.length > 0 ? (
        <div className="table_users_list">
          <table style={{ border: "solid 1px #dedede" }}>
            <thead>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "20%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (orderBy === "email") {
                      setOrderBy("emailReverse");
                    } else {
                      setOrderBy("email");
                    }
                  }}
                >
                  {t("admin.email")}
                  {orderBy === "email" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "emailReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "20%",
                    textAlign: "center",
                  }}
                >
                  {t("admin.customers")}
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "14px", textAlign: "center" }}>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "20%",
                    textAlign: "center",
                  }}
                >
                  <MDInput
                    type="input"
                    label={t("admin.email")}
                    fullWidth
                    onChange={(e) => {
                      setEmailFilter(e.target.value);
                    }}
                  />
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "20%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
              </tr>
              {users.map((el, index) => (
                <tr style={{ borderBottom: "solid 1px #dedede" }} key={`user-${index.toString()}`}>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.email}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.clientsManaged && el.clientsManaged.length > 0
                      ? el.clientsManaged.map((cli) =>
                          cli.name ? (
                            <div key={`user-client-${index.toString()}-${cli.name}`}>
                              <IconButton
                                onClick={() => {
                                  setElementView(cli);
                                }}
                                size="small"
                                disableRipple
                              >
                                {cli.name}
                              </IconButton>
                            </div>
                          ) : null
                        )
                      : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
        </div>
      ) : (
        <div>{t("admin.no_sellers")}</div>
      )}
    </DashboardLayout>
  );
}

export default UserList;
