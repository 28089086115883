const passwordRecovery = {
  en: {
    translation: {
      title: "OTP Initial setup",
      download_authenticator:
        "Download a password authenticator app (Google Authenticator, Free OTP)",
      scan_code: "Scansiona il QR code",
      copy_paste_key: "Copy and paste the key on your password manager",
      cant_scan_code: "Non riesci a scansionare il codice?",
      enter_code_first_time: "Enter the gennerated code below",
      enter_code: "Enter the 6-digit security code indicated in the authenticator app for",
      enter_code_email:
        "Enter the 6-digit security code indicated in the message sent to your email",
      verify: "Verify",
      error_sending_code: "Error while sending code to your email, please try again later",
    },
  },

  it: {
    translation: {
      title: "OTP setup iniziale",
      download_authenticator:
        "Scarica un'app di autenticazione password (Google Authenticator, Free OTP)",
      scan_code: "Scan the QR code",
      copy_paste_key: "Copia e incolla la chiave nel tuo password manager",
      cant_scan_code: "Can't scan the code?",
      enter_code_first_time: "Digita qua sotto il codice generato",
      enter_code: "Digita il codice a sei cifre indicato sull'app authenticator per l'email",
      enter_code_email: "Digita il codice a sei cifre indicato sul messaggio per l'email",
      verify: "Verifica",
      error_sending_code: "Errore durante l'invio del codice alla mail, riprova più tardi",
    },
  },
};

export default passwordRecovery;
