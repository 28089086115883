// MUI components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function Error({ message }) {
  const { t } = useTranslation();

  return (
    <MDBox mt={4} mb={1}>
      <MDTypography variant="h6" fontSize="13px" color="error">
        {t("errors.something_goes_wrong")}
      </MDTypography>
      <MDTypography fontSize="11px" color="error">
        {message || t("errors.check_input")}
      </MDTypography>
    </MDBox>
  );
}

Error.defaultProps = {
  message: null,
};

Error.propTypes = {
  message: PropTypes.string,
};

export default Error;
