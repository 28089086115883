const user = {
  en: {
    translation: {
      hello: "Hello",
      email: "Email",
      no_clients: "There are no customers linked to your user",
      you_have: "You have",
      customers: "customers",
      customer: "Customer",
      change_password: "Change password",
      vat_id: "Vat ID",
      service: "Service",
      credit_cards: "Credit cards monitored",
      combolists: "Combolists monitored",
      emails_linked: "Emails to send notifications",
      cookies: "Cookies monitored",
      cookies_client: "Cookies clients monitored",
      domains_to_monitor_passwords: "Domains to monitor passwords",
      domains_to_monitor_passwords_url: "Domains and url to monitor passwords",
      domains_to_monitor_passwords_apps: "Apps domains to monitor passwords",
      usernames_to_monitor: "Usernames to look for",
      ransomware_to_monitor: "Ransomwares to look for",
      password: "Password",
      confirm_password: "Confirm password",
      old_password: "Old password",
      change_password_success_text: "Your request of changing password was succesfully",
      change_password_error_text:
        "It seems like your old password is not correct, please, try again",
      add_credential: "Add credential",
      add_credential_explanation:
        "Add one of your email to monitor if there are results in the combolists. You can monitor a limited number of credentials, depending on your profile",
      new_credential: "New credential",
      wrong_email: "Please, add a correct email",
      add_credential_success_title: "Request added",
      add_credential_success_text:
        "Good job, we sent a message to the address you entered, confirm it and we will monitor your email, we will need some days to process potential previous compromises",
      email_already_used:
        "It seems like the email you entered is already under control, if it is not linked to your account, please contact our support",
      change_password_empty_field: "This field cannot be left empty",
      change_password_password_mismatch: "Please enter the same password",
      contract_start: "Contract start",
      contract_expiration: "Contract expiration",
      expired: "expired",
      add_credential_error_title: "Error adding credentials",
      add_credential_error_text:
        "An error occurred while trying to add the credential, please check if you still have slots available, that your plan includes checking them or try again later",
      notification_form_btn: "Confirm changes",
      success: "Success",
      error: "Error",
      push: "Push",
      weekly: "Weekly",
      monthly: "Monthly",
      quarterly: "Quarterly",
      periodic_reports: "Periodic reports",
      alerts: "Alerts",
      confirm_popup_delete_user_title: "Delete user",
      confirm_popup_delete_user_text: "Do you want to delete the user?",
      success_popup_delete_user_title: "User deleted",
      success_popup_delete_user_text: "The selected user has been deleted",
      change_country: "Change country",
      add_country: "Add country",
      country: "Country",
      new_country: "New country",
    },
  },
  it: {
    translation: {
      hello: "Ciao",
      email: "Email",
      no_clients: "Non ci sono ancora clienti associati al tuo utente",
      you_have: "Hai",
      customers: "clienti",
      customer: "Cliente",
      change_password: "Cambia password",
      vat_id: "Partita IVA",
      service: "Servizio",
      credit_cards: "Carte di credito monitorate",
      combolists: "Combolists monitorate",
      emails_linked: "Email per le notifiche",
      cookies: "Cookies monitorati",
      cookies_client: "Cookies con clienti monitorati",
      domains_to_monitor_passwords: "Domini per monitorare le password",
      domains_to_monitor_passwords_url: "Domini con url per monitorare le password",
      domains_to_monitor_passwords_apps: "Domini delle app per monitorare le password",
      usernames_to_monitor: "Username da cercare",
      ransomware_to_monitor: "Ransomware da monitorare",
      password: "Password",
      confirm_password: "Conferma password",
      old_password: "Vecchia password",
      change_password_success_text: "La tua richiesta di cambio password è andata a buon fine",
      change_password_error_text:
        "Sembra che la tua vecchia password non sia corretta, per favore, riprova",
      add_credential: "Aggiungi credenziale",
      add_credential_explanation:
        "Aggiungi una delle tue email da monitorare nelle combolists. Puoi monitorare un numero limitato di credenziali, in base al tuo profilo",
      new_credential: "Nuova credenziale",
      wrong_email: "Per favore, aggiungi un indirizzo email corretto",
      add_credential_success_title: "Richiesta aggiunta",
      add_credential_success_text:
        "Complimenti, abbiamo mandato un messaggio all'indirizzo che hai inserito, segui le istruzioni del messaggio e inizieremo a monitorare l'indirizzo email che hai appena aggiunto, ci servirà qualche giorno per trovare eventuali compromissioni passate.",
      email_already_used:
        "It seems like the email you entered is already under control, if it is not linked to your account, please contact our support",
      change_password_empty_field: "Questo campo non può essere vuoto",
      change_password_password_mismatch: "Per favore inserisci la stessa password",
      contract_start: "Inizio contratto",
      contract_expiration: "Scadenza contratto",
      expired: "scaduto",
      add_credential_error_title: "Errore aggiunta credenziali",
      add_credential_error_text:
        "C'è stato un errore durante l'aggiunta della credenziale, controlla di avere ancora slot disponibili, che il tuo piano lo preveda o riprova più tardi",
      notification_form_btn: "Conferma modifiche",
      success: "Successo",
      error: "Errore",
      push: "Push",
      weekly: "Settimanale",
      monthly: "Mensile",
      quarterly: "Trimestrale",
      periodic_reports: "Report periodici",
      alerts: "Notifiche",
      confirm_popup_delete_user_title: "Elimina utente",
      confirm_popup_delete_user_text: "Vuoi eliminare l'utente?",
      success_popup_delete_user_title: "Utente eliminato",
      success_popup_delete_user_text: "L'utente è stato eliminato con successo",
      change_country: "Cambia paese",
      add_country: "Aggiungi paese",
      country: "Paese",
      new_country: "Nuovo paese",
    },
  },
};

export default user;
