import config from "config/config";
import getTokens from "api/getTokens";

const { baseUrl, clientUrl } = config;

/**
 * @typedef {Object} AlertsNotificationsSettings
 * @property {Boolean} email
 * @property {Booolean} push
 */

/**
 * @typedef {Object} ReportsNotificationsSettings
 * @property {Boolean} weekly
 * @property {Boolean} monthly
 * @property {Boolean} quarterly
 */

/**
 * @typedef {Object} MarketingNotificationsSettings
 * @property {Boolean} email
 * @property {Boolean} push
 */

/**
 * @typedef {Object} NotificationsSettings
 * @property {AlertsNotificationsSettings} alerts
 * @property {ReportsNotificationsSettings} reports
 * @property {MarketingNotificationsSettings} marketing
 */

/**
 * @param {NotificationsSettings} settings
 */
export default function setNotificationsSettings(settings) {
  const endpoint = `${baseUrl}/v1/users/notifications-settings`;

  let access = "";
  const tokens = getTokens();
  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  }

  const options = {
    method: "PUT",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl || ""}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
    body: JSON.stringify(settings),
  };

  return fetch(endpoint, options)
    .then((response) => response.status >= 200 && response.status <= 299)
    .catch((e) => console.error("error", e.message));
}
