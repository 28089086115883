// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import getStealers from "api/stealer/getAllStealers";
import getCompleteStealers from "api/stealer/getAllCompleteStealers";
import getIncompleteStealers from "api/stealer/getAllIncompleteStealers";
import getStealersBySites from "api/stealer/getStealersBySites";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import { useMaterialUIController } from "context";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Pagination from "layouts/dashboards/stealers/pagination";
import StealersStatistics from "layouts/dashboards/analytics/components/stealersStatistics";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MDInput from "components/MDInput";
import ServerError from "utilities/ServerError";
import { Drawer } from "@mui/material";

// Utilities
import { getReasonToShow, ReasonToShow } from "utilities/getReasonToShow";
import { getHostFromUrl } from "utilities/StealerUrls";

// APIs
import getCsv from "api/stealer/csvDownloader";

// External components
import StealerDetail from "../detailsStealer";

function Stealers() {
  const [attacks, setAttacks] = useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const urlNow = window.location.href;
  let stealerFromUrl = "all";
  if (urlNow && urlNow.endsWith("incompleti")) {
    stealerFromUrl = "incomplete";
  } else if (urlNow && urlNow.endsWith("completi")) {
    stealerFromUrl = "complete";
  }
  const [stealersType] = useState(stealerFromUrl);
  const [nation, setNation] = useState("");
  const [domain] = useState("");
  const [showOriginalItemId, setShowOriginalItemId] = useState("");
  const [reason, setReason] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [hash] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [fieldsShown] = useState(["Nazione", "Dominio", "Motivo", "Completo"]);
  const [showDomains, setShowDomains] = useState(false);
  const [domainsToShow, setDomainsToShow] = useState(null);
  const [showUsernames, setShowUsernames] = useState(false);
  const [usernamesToShow, setUsernamesToShow] = useState(null);
  const [isRequestReady, setIsRequestReady] = useState(false);
  const [orderBy, setOrderBy] = useState("dateReverse");
  const [showHidden] = useState(false);
  const [serverError, setServerError] = useState({
    stealersGrid: false,
    stealersTable: false,
  });
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [attacksIds, setAttacksIds] = useState([]);

  const [controller, _] = useMaterialUIController();
  const { client, user } = controller;
  const { t } = useTranslation();

  function setRequest(res) {
    if (res?.results?.length > 0) {
      setAttacks(res.results);
    }

    if (res && res.length > 0) {
      setAttacks(res);
    } else if (res.length === 0 || (res.results && res.results.length === 0)) {
      setAttacks([]);
    }

    setIsRequestReady(true);
  }

  function refreshAttacks() {
    setIsRequestReady(false);
    if (!client || client === "*") {
      let functionToUse = null;
      switch (stealersType) {
        case "all":
          functionToUse = getStealers;
          break;
        case "complete":
          functionToUse = getCompleteStealers;
          break;
        case "incomplete":
          functionToUse = getIncompleteStealers;
          break;
        default:
          functionToUse = getStealers;
      }
      functionToUse(
        page,
        limit,
        nation,
        domain,
        reason,
        hash,
        showHidden,
        dateFrom,
        dateTo,
        orderBy
      )
        .then((res) => {
          if (res && res.totalPages) {
            setPages(res.totalPages);
          }
          setRequest(res);
        })
        .catch((err) => {
          console.log("error", err);
          setServerError({ ...serverError, stealersTable: true });
        });
    } else if (
      (client && client.cookies && client.cookies.length > 0) ||
      (client && client.passwords && client.passwords.length > 0)
    ) {
      let completeFilter = "";
      if (stealersType === "complete" || stealersType === "incomplete") {
        completeFilter = stealersType;
      }
      let domainsToSearch = [];
      if (client && client.cookies && client.cookies.length > 0) {
        client.cookies.forEach((cookieUrl) => {
          if (cookieUrl.url) {
            domainsToSearch.push(cookieUrl.url);
          }
        });
      }
      if (client && client.passwords && client.passwords.length > 0) {
        domainsToSearch = [...domainsToSearch, client.passwords];
      }
      getStealersBySites(
        domainsToSearch,
        page,
        limit,
        nation,
        domain,
        reason,
        hash,
        completeFilter,
        false,
        dateFrom,
        dateTo,
        orderBy
      )
        .then((res) => {
          setPages(res.totalPages);

          setRequest(res);
        })
        .catch((err) => console.log("error", err));
    }
  }

  useEffect(() => {
    refreshAttacks();
  }, [
    limit,
    page,
    stealersType,
    nation,
    domain,
    reason,
    hash,
    showHidden,
    dateFrom,
    dateTo,
    orderBy,
  ]);

  function getDomainShown(domains) {
    const arr = [];
    if (domains && domains.length > 0) {
      domains.forEach((item) => arr.push(item));
    } else {
      return "N/A";
    }

    if (arr.length > 1) {
      return `${arr[0]} + ${arr.length - 1}`;
    }

    return arr[0];
  }

  function getTags(tags) {
    let tagsDisplayed = "";
    tags.forEach((tag, i) => {
      if (i > 0) {
        tagsDisplayed += `, ${tag}`;
      } else {
        tagsDisplayed += tag;
      }
    });
    return tagsDisplayed;
  }

  function getCalendar(value, changeFunction, label) {
    return (
      <div style={{ position: "relative", marginRight: "10px" }}>
        <Datetime
          inputProps={{
            placeholder: label,
            value,
          }}
          closeOnSelect
          timeFormat={false}
          value={value}
          onChange={(e) => {
            changeFunction(moment(e).format("L"));
          }}
        />
        {value ? (
          <IconButton
            onClick={() => {
              changeFunction("");
            }}
            style={{ position: "absolute", right: "0", top: "6px" }}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium">close</Icon>
          </IconButton>
        ) : null}
      </div>
    );
  }

  function getInput(value, changeFunction, type) {
    return (
      <div style={{ position: "relative", marginRight: "10px" }}>
        <MDInput
          label={type}
          fullWidth
          onChange={(e) => {
            changeFunction(e.target.value);
          }}
          style={{ marginBottom: "30px" }}
        />
        {value ? (
          <IconButton
            onClick={() => {
              changeFunction("");
            }}
            style={{ position: "absolute", right: "0", top: "6px" }}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium">close</Icon>
          </IconButton>
        ) : null}
      </div>
    );
  }

  function getSelectReason() {
    return (
      <div style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
        <div
          style={{ lineHeight: "16px", marginRight: "15px", fontSize: "0.8em", marginLeft: "10px" }}
        >
          Dato rubato:
        </div>
        <select
          onChange={(e) => {
            if (e.target.value === "all") {
              setReason("");
            } else {
              setReason(e.target.value);
            }
          }}
          className="selectDefault"
          style={{
            padding: "0.75rem",
            borderRadius: "0.375rem",
            borderColor: "#d2d6da",
          }}
          value={reason}
        >
          <option value="all">{t("stealers.all")}</option>
          <option value="credentials">{t("stealers.credentials")}</option>
          <option value="cookies">{t("stealers.cookies")}</option>
        </select>
      </div>
    );
  }

  useEffect(() => {
    refreshAttacks();
    // refreshCountersClient();
  }, [client]);

  const handleCsvDownloadBtn = async () => {
    try {
      // Get csv from API
      const response = await getCsv(dateFrom, dateTo, reason, orderBy);
      const data = await response.text();

      // File link
      const element = document.createElement("a");
      element.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
      element.download = `table.csv`;

      // Simulate link click
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();

      // Remove the "a" element after the download
      document.body.removeChild(element);
    } catch (error) {
      console.log("err --->", error);
    }
  };

  useEffect(() => {
    const idsArr = attacks.map((attack) => attack.id);
    setAttacksIds(idsArr);
  }, [attacks]);

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2 className="page-title">{t("stealers.stolen_credentials")}</h2>
      <div>
        {t("stealers.page_description", { returnObjects: true }).map((line) => (
          <p style={{ fontSize: "small" }} key={line}>
            {line}
          </p>
        ))}
      </div>
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            <StealersStatistics columns={3} />
          </Grid>
        </MDBox>
      </MDBox>
      <div style={{ margin: "30px 0", width: "100%", textAlign: "left" }}>
        <IconButton
          onClick={() => {
            setShowFilters(!showFilters);
          }}
          style={{ marginRight: "15px" }}
          className="default-button"
          size="small"
          disableRipple
        >
          {t("filter")}{" "}
          {showFilters ? (
            <ArrowDropUpIcon style={{ marginLeft: "10px" }} fontSize="medium" />
          ) : (
            <ArrowDropDownIcon style={{ marginLeft: "10px" }} fontSize="medium" />
          )}
        </IconButton>
        <IconButton
          onClick={() => {
            refreshAttacks();
          }}
          className="default-button"
          style={{ marginRight: "15px" }}
          size="small"
          disableRipple
        >
          {t("refresh")}{" "}
          <Icon style={{ marginLeft: "10px" }} fontSize="medium">
            replay
          </Icon>
        </IconButton>
        <IconButton
          className="default-button"
          size="small"
          disableRipple
          fontSize="medium"
          onClick={handleCsvDownloadBtn}
        >
          <Icon fontSize="medium" style={{ marginRight: "10px" }}>
            download
          </Icon>
          Download CSV
        </IconButton>
      </div>
      {!serverError.stealersTable ? (
        <>
          {showFilters ? (
            <div style={{ marginBottom: "30px" }}>
              <div>
                <div className="label-filters" style={{ marginBottom: "10px" }}>
                  {t("search")}:
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", maxWidth: "650px" }}>
                    {getCalendar(dateFrom, setDateFrom, t("stealers.date_from"))}
                    {getCalendar(dateTo, setDateTo, t("stealers.date_to"))}
                    {getSelectReason()}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        lineHeight: "16px",
                        marginRight: "15px",
                        fontSize: "0.8em",
                        marginLeft: "10px",
                      }}
                    >
                      {t("stealers.elements_displayed")}
                    </div>
                    <select
                      onChange={(e) => {
                        setLimit(e.target.value);
                      }}
                      value={limit}
                      className="selectDefault"
                      style={{
                        padding: "0.75rem",
                        borderRadius: "0.375rem",
                        borderColor: "#d2d6da",
                      }}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="popupInfoContainer">
            {showOriginalItemId && user && user.role && user.role === "admin" ? (
              <SweetAlert
                style={{ display: "block", position: "relative", textAlign: "left" }}
                title=""
                onConfirm={() => {
                  setShowOriginalItemId("");
                }}
                confirmBtnText={t("close")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                <div className="popupButtonEdit">
                  <Tooltip title={t("close")} placement="top">
                    <IconButton
                      onClick={() => {
                        setShowOriginalItemId("");
                      }}
                      size="small"
                      disableRipple
                    >
                      <Icon fontSize="medium">close</Icon>
                    </IconButton>
                  </Tooltip>
                </div>
                <Tooltip title={`${t("copy")} (click)`} placement="top">
                  <IconButton
                    className="stealers-details-domains-copy-btn"
                    style={{
                      width: "100%",
                      color: "#344767",
                      fontWeight: "400",
                      textTransform: "none",
                      fontSize: "18px",
                      padding: "0px",
                    }}
                    variant="text"
                    onClick={() => {
                      navigator.clipboard.writeText(showOriginalItemId);
                    }}
                  >
                    {showOriginalItemId}&nbsp;
                    <Icon fontSize="medium">copy</Icon>
                  </IconButton>
                </Tooltip>
              </SweetAlert>
            ) : null}
            {showDomains ? (
              <SweetAlert
                style={{ display: "block", position: "relative", textAlign: "left" }}
                title=""
                onConfirm={() => {
                  setShowDomains(false);
                  setDomainsToShow(null);
                }}
                confirmBtnText={t("close")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                <div className="popupButtonEdit">
                  <Tooltip title={t("close")} placement="top">
                    <IconButton
                      onClick={() => {
                        setShowDomains(false);
                        setDomainsToShow(null);
                      }}
                      size="small"
                      disableRipple
                    >
                      <Icon fontSize="medium">close</Icon>
                    </IconButton>
                  </Tooltip>
                </div>
                {domainsToShow.map((item, index) => (
                  <p key={index.toString()}>{item}</p>
                ))}
              </SweetAlert>
            ) : null}
            {showUsernames ? (
              <SweetAlert
                style={{ display: "block", position: "relative", textAlign: "left" }}
                title=""
                onConfirm={() => {
                  setShowUsernames(false);
                  setUsernamesToShow(null);
                }}
                confirmBtnText={t("close")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                <div className="popupButtonEdit">
                  <Tooltip title={t("close")} placement="top">
                    <IconButton
                      onClick={() => {
                        setShowUsernames(false);
                        setUsernamesToShow(null);
                      }}
                      size="small"
                      disableRipple
                    >
                      <Icon fontSize="medium">close</Icon>
                    </IconButton>
                  </Tooltip>
                </div>
                {usernamesToShow.map((item, index) => (
                  <p key={index.toString()}>{item}</p>
                ))}
              </SweetAlert>
            ) : null}
            <div>
              <div className="table_users_list">
                <table className="table-content" cellSpacing="0" cellPadding="0">
                  <thead>
                    <tr>
                      <th
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (orderBy === "dateReverse") {
                            setOrderBy("date");
                          } else {
                            setOrderBy("dateReverse");
                          }
                        }}
                      >
                        {orderBy === "date" ? (
                          <ArrowDropDownIcon
                            style={{ marginBottom: "-7px", marginRight: "15px" }}
                            fontSize="medium"
                          />
                        ) : null}
                        {orderBy === "dateReverse" ? (
                          <ArrowDropUpIcon
                            style={{ marginBottom: "-7px", marginRight: "15px" }}
                            fontSize="medium"
                          />
                        ) : null}
                        {t("stealers.date")}
                      </th>
                      {fieldsShown.includes("Nazione") ? (
                        <th
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (orderBy === "nation") {
                              setOrderBy("nationReverse");
                            } else {
                              setOrderBy("nation");
                            }
                          }}
                        >
                          {orderBy === "nation" ? (
                            <ArrowDropDownIcon
                              style={{ marginBottom: "-7px", marginRight: "15px" }}
                              fontSize="medium"
                            />
                          ) : null}
                          {orderBy === "nationReverse" ? (
                            <ArrowDropUpIcon
                              style={{ marginBottom: "-7px", marginRight: "15px" }}
                              fontSize="medium"
                            />
                          ) : null}
                          {t("stealers.nation")}
                        </th>
                      ) : null}
                      {fieldsShown.includes("Dominio") ? <th>{t("stealers.domain")}</th> : null}
                      {fieldsShown.includes("Tags") ? <th>{t("stealers.tags")}</th> : null}
                      {fieldsShown.includes("MachineId") ? (
                        <th>{t("stealers.machine_id")}</th>
                      ) : null}
                      {fieldsShown.includes("SistemaOperativo") ? (
                        <th>{t("stealers.so")}</th>
                      ) : null}
                      {fieldsShown.includes("Hash") ? (
                        <th
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (orderBy === "hash") {
                              setOrderBy("hashReverse");
                            } else {
                              setOrderBy("hash");
                            }
                          }}
                        >
                          {orderBy === "hash" ? (
                            <ArrowDropDownIcon
                              style={{ marginBottom: "-7px", marginRight: "15px" }}
                              fontSize="medium"
                            />
                          ) : null}
                          {orderBy === "hashReverse" ? (
                            <ArrowDropUpIcon
                              style={{ marginBottom: "-7px", marginRight: "15px" }}
                              fontSize="medium"
                            />
                          ) : null}
                          {t("stealers.hash")}
                        </th>
                      ) : null}
                      {fieldsShown.includes("Motivo") ? <th>{t("stealers.reason")}</th> : null}
                      <th
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {t("stealers.info")}
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {t("stealers.status")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isRequestReady ? (
                      <>
                        {attacks && attacks.length > 0 ? (
                          <>
                            {attacks.map((el, index) => {
                              const reasonsToShow = getReasonToShow(el) || [];
                              const reasonsToShowText = reasonsToShow
                                .map((e) => ReasonToShow.getText(e, t))
                                .join(", ");

                              let domainsList = [];
                              let usernamesList = [];

                              el.results.forEach((item) => {
                                if (item.results) {
                                  if (item.results.domains && item.results.domains.length > 0) {
                                    domainsList = domainsList.concat(item.results.domains);
                                  }
                                  if (item.results.paths && item.results.paths.length > 0) {
                                    // TODO: anything?
                                  }
                                  if (
                                    item.results.passwordsApps &&
                                    item.results.passwordsApps.length > 0
                                  ) {
                                    const parsed = item.results.passwordsApps.map((e) => {
                                      if (e?.toLowerCase().startsWith("android:"))
                                        return getHostFromUrl(e);
                                      return e;
                                    });
                                    domainsList = domainsList.concat(parsed);
                                  }
                                  if (item.results.cookies && item.results.cookies.length > 0) {
                                    domainsList = domainsList.concat(item.results.cookies);
                                  }
                                  if (
                                    item.results.usernamesAndDomains &&
                                    item.results.usernamesAndDomains.length > 0
                                  ) {
                                    const domainsForUsernames =
                                      item.results.usernamesAndDomains.map((el) => el.domain);
                                    domainsList = domainsList.concat(domainsForUsernames);
                                  }
                                  if (item.results.usernames && item.results.usernames.length > 0) {
                                    usernamesList = usernamesList.concat(item.results.usernames);
                                  }
                                  if (
                                    item.results.combosAndDomains &&
                                    item.results.combosAndDomains.length > 0
                                  ) {
                                    const domainsForUsernames = item.results.combosAndDomains.map(
                                      (el) => el.domain
                                    );
                                    domainsList = domainsList.concat(domainsForUsernames);
                                    if (!reasonsToShow) {
                                      reasonsToShow = ReasonToShow.CREDENTIALS;
                                    }
                                  } else if (
                                    item.results.combos &&
                                    item.results.combos.length > 0
                                  ) {
                                    domainsList = domainsList.concat(item.results.combos);
                                  }
                                }
                              });

                              if (domainsList?.length > 0) {
                                domainsList = [...new Set(domainsList)];
                              }
                              if (usernamesList?.length > 0) {
                                usernamesList = [...new Set(usernamesList)];
                              }

                              return (
                                <tr key={`stealer-${index.toString()}`}>
                                  <td>
                                    {el.dateProcessed || el.date
                                      ? `${moment(el.dateProcessed || el.date).format(
                                          "DD/MM/YYYY"
                                        )}`
                                      : ""}
                                  </td>
                                  {fieldsShown.includes("Nazione") ? (
                                    <td>
                                      {el.countryCode ? t(`countryCode.${el.countryCode}`) : "-"}
                                    </td>
                                  ) : null}
                                  {fieldsShown.includes("Dominio") ? (
                                    <td style={{ maxWidth: "250px" }}>
                                      {el.results && el.results.some((clie) => clie.results) ? (
                                        domainsList?.length > 1 ? (
                                          <button
                                            type="button"
                                            className="emply-label-button"
                                            onClick={() => {
                                              if (domainsList && domainsList.length > 0) {
                                                setShowDomains(true);
                                                setDomainsToShow(domainsList);
                                              }
                                            }}
                                          >
                                            {getDomainShown(domainsList)}
                                          </button>
                                        ) : (
                                          getDomainShown(domainsList)
                                        )
                                      ) : (
                                        t("stealers.no_customer_associated")
                                      )}
                                    </td>
                                  ) : null}
                                  {fieldsShown.includes("Tags") ? (
                                    <td>{el.tags && el.tags.length > 0 ? getTags(el.tags) : ""}</td>
                                  ) : null}
                                  {fieldsShown.includes("MachineId") ? (
                                    <td>{el.machineID ? el.machineID : ""}</td>
                                  ) : null}
                                  {fieldsShown.includes("SistemaOperativo") ? (
                                    <td>{el.OS ? el.OS : ""}</td>
                                  ) : null}
                                  {fieldsShown.includes("Hash") ? (
                                    <td>{el.filelistHash ? el.filelistHash : ""}</td>
                                  ) : null}
                                  {fieldsShown.includes("Motivo") ? (
                                    <td>
                                      {usernamesList?.length > 0 ? (
                                        <button
                                          type="button"
                                          className="emply-label-button"
                                          onClick={() => {
                                            if (usernamesList?.length > 0) {
                                              setShowUsernames(true);
                                              setUsernamesToShow(usernamesList);
                                            }
                                          }}
                                        >
                                          {reasonsToShowText}
                                        </button>
                                      ) : (
                                        reasonsToShowText
                                      )}
                                    </td>
                                  ) : null}
                                  <td style={{ textAlign: "center" }}>
                                    {user &&
                                    user.role &&
                                    user.role === "admin" &&
                                    el.originalItemId ? (
                                      <Tooltip
                                        title={t("stealers.get_original_item_id")}
                                        placement="top"
                                      >
                                        <IconButton
                                          onClick={() => {
                                            setShowOriginalItemId(el.originalItemId);
                                          }}
                                          size="small"
                                          disableRipple
                                        >
                                          <Icon fontSize="medium">info</Icon>
                                        </IconButton>
                                      </Tooltip>
                                    ) : null}
                                    <IconButton
                                      onClick={() => {
                                        setCurrentIndex(index);
                                        setIsDetailOpen(true);
                                      }}
                                      className="default-button"
                                      size="small"
                                      disableRipple
                                    >
                                      {t("details")}
                                    </IconButton>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {user &&
                                    user.id &&
                                    el.managedBy &&
                                    el.managedBy.length > 0 &&
                                    el.managedBy.includes(user.id) ? (
                                      <Tooltip title={t("stealers.managed")} placement="top">
                                        <Icon
                                          style={{
                                            marginLeft: "5px",
                                            color: "green",
                                          }}
                                          fontSize="medium"
                                        >
                                          check
                                        </Icon>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title={t("stealers.not_managed_yet")}
                                        placement="top"
                                      >
                                        <Icon
                                          style={{
                                            marginLeft: "5px",
                                            color: "red",
                                          }}
                                          fontSize="medium"
                                        >
                                          close
                                        </Icon>
                                      </Tooltip>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr className="table-tr-no-results">
                            <td />
                            <td />
                            <td />
                            <td className="table-no-results-box">{t("stealers.no_stealer_yet")}</td>
                            <td />
                            <td />
                          </tr>
                        )}
                      </>
                    ) : (
                      <tr>
                        <td />
                        <td />
                        <td
                          style={{
                            width: "100%",
                            display: "block",
                            padding: "10px 0",
                            position: "relative",
                          }}
                        >
                          <div className="loader" />
                        </td>
                        <td />
                        <td />
                      </tr>
                    )}
                  </tbody>
                </table>
                {pages && pages > 1 ? (
                  <Pagination setPage={setPage} pages={pages} active={page} />
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <ServerError />
      )}
      <Drawer
        anchor="right"
        open={isDetailOpen}
        onClose={handleCloseDetail}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "1000px",
            margin: 0,
            borderRadius: 0,
            height: "100vh",
          },
        }}
      >
        <StealerDetail
          currentIndex={currentIndex}
          attacksIds={attacksIds}
          handleCloseDetail={handleCloseDetail}
        />
      </Drawer>
    </DashboardLayout>
  );
}

export default Stealers;
