// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import getTokens from "api/getTokens";
import getRoles from "api/users/getRoles";
import createUser from "api/users/createUser";
import addLog from "api/logs/addLog";
import getAllClients from "api/clients/getAllClients";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import SweetAlert from "react-bootstrap-sweetalert";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";

const MIN_CHAR_SEARCH = 1;

function AddUser() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("user");
  const [roles, setRoles] = useState([]);
  const [hasMobile, setHasMobile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [popup, setPopup] = useState(false);
  const [elementInList, setElementInList] = useState(0);
  const [clients, setClients] = useState([]);
  const [clientsSelected, setClientsSelected] = useState([]);
  const [originalClients, setOriginalClients] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [client, setClient] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [shouldReceiveReport, setShouldReceiveReport] = useState(false);
  const [mustChangePassword, setMustChangePassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  function getAllRoles() {
    getRoles()
      .then((res) => {
        if (res && res.length > 0) {
          setRoles(res);
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error while getting roles", err));
  }

  const validateEmail = (em) =>
    String(em)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  useEffect(() => {
    // const tokens = getTokens();
    getAllRoles();
    getAllClients(1, 1000)
      .then((res) => {
        if (res.results && res.results.length > 0) {
          setClients(res.results);
          setOriginalClients(res.results);
          setAllClients(res.results);
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }, []);

  function getInput(type, editFunction, defaultValue) {
    if (type === "password" || type === "Password") {
      return (
        <div style={{ position: "relative" }}>
          <MDInput
            type={!showPassword ? "password" : "input"}
            label={type}
            fullWidth
            defaultValue={defaultValue}
            onChange={(e) => {
              editFunction(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              size="small"
              disableRipple
            >
              {showPassword ? (
                <Icon fontSize="medium">visibility</Icon>
              ) : (
                <Icon fontSize="medium">lock</Icon>
              )}
            </IconButton>
          </div>
        </div>
      );
    }
    return (
      <MDInput
        type="input"
        label={type}
        fullWidth
        defaultValue={defaultValue}
        className={type === "Email" && errorEmail ? "input-error" : ""}
        onChange={(e) => {
          if (type === "Email") {
            if (validateEmail(e.target.value)) {
              setErrorEmail(false);
            } else {
              setErrorEmail(true);
            }
            editFunction(e.target.value);
          } else {
            editFunction(e.target.value);
          }
        }}
        style={{ marginBottom: "30px" }}
      />
    );
  }

  function getSelect(options, editFunction, defaultValue) {
    return (
      <select
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        defaultValue={defaultValue}
        className="selectDefault"
        style={{
          width: "100%",
          padding: "0.75rem",
          borderRadius: "0.375rem",
          borderColor: "#d2d6da",
          marginBottom: "30px",
        }}
      >
        {options.map((el, index) => (
          <option value={el} key={`option-role-${index.toString()}`}>
            {el}
          </option>
        ))}
      </select>
    );
  }

  function getInputArray() {
    return (
      <div style={{ position: "relative" }}>
        <MDInput
          type="input"
          label={t("admin.customer")}
          fullWidth
          value={client}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (clients && clients.length > 0 && clients[elementInList]) {
                clientsSelected.push(clients[elementInList].id);
                setClient("");
                const newOriginals = [].concat(originalClients);
                const indexToDelete = newOriginals.findIndex(
                  (del) => del.id === clients[elementInList].id
                );
                if (indexToDelete >= 0) {
                  newOriginals.splice(indexToDelete, 1);
                }
                setOriginalClients(newOriginals);
                setClients(newOriginals);
              }
            } else if (e.key === "ArrowDown") {
              if (clients && elementInList < clients.length - 1) {
                setElementInList(elementInList + 1);
              }
            } else if (e.key === "ArrowUp") {
              if (elementInList > 0) {
                setElementInList(elementInList - 1);
              }
            }
          }}
          onChange={(e) => {
            setClient(e.target.value);
            const filterClients = [].concat(originalClients);
            setClients(
              filterClients.filter((el) =>
                el.name.toLowerCase().includes(e.target.value.toLowerCase())
              )
            );
          }}
          style={{ marginBottom: "30px" }}
        />
        {client && client.length > MIN_CHAR_SEARCH && clients && clients.length > 0 ? (
          <div className="customDropdownContainer">
            {clients.map((el, index) => (
              // eslint-disable-next-line
              <div
                key={`client-dropdown-${index.toString()}`}
                className={`customDropdownClient ${index === elementInList ? "selected" : ""}`}
                onClick={() => {
                  clientsSelected.push(el.id);
                  setClient("");
                  const newOriginals = [].concat(originalClients);
                  const indexToDelete = newOriginals.findIndex((del) => del.id === el.id);
                  if (indexToDelete >= 0) {
                    newOriginals.splice(indexToDelete, 1);
                  }
                  setOriginalClients(newOriginals);
                  setClients(newOriginals);
                }}
              >
                {el.name}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }

  function viewArrayElements() {
    return clientsSelected.map((el, index) => {
      const indexToFind = allClients.findIndex((item) => item.id === el);
      let element = null;
      if (indexToFind >= 0) {
        element = allClients[indexToFind];
      }
      return (
        <div key={`client-selected-el-${index.toString()}`} className="clientsViewArrayElement">
          {element.name}
          <IconButton
            onClick={() => {
              const newClientsSelected = [].concat(clientsSelected);
              newClientsSelected.splice(index, 1);
              setClientsSelected(newClientsSelected);
              const elementToRestore = allClients.find((restore) => restore.id === element.id);
              if (elementToRestore) {
                const newOriginals = [].concat(originalClients);
                newOriginals.push(elementToRestore);
                setOriginalClients(newOriginals);
                setClients(newOriginals);
              }
            }}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium">close</Icon>
          </IconButton>
        </div>
      );
    });
  }

  return (
    <DashboardLayout>
      {popup ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.user_created")}
          success
          onConfirm={() => {
            setPopup(false);
            navigate("/userlist");
          }}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.user_created_text")}
        </SweetAlert>
      ) : null}
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card>
            <MDBox variant="gradient">
              <MDBox p={5}>
                <MDBox display="flex" justifyContent="space-between">
                  <MDBox>
                    {getInput(t("admin.name"), setName, name)}
                    {getInput(t("admin.email"), setEmail, email)}
                    {getInput(t("admin.password"), setPassword, password)}
                    {getSelect(roles, setRole, role)}
                    {getInputArray()}
                    {clientsSelected && clientsSelected.length > 0 ? (
                      <div className="clientsViewArrayContainer">{viewArrayElements()}</div>
                    ) : null}
                    <div>
                      <input
                        type="checkbox"
                        style={{ marginBottom: "30px" }}
                        checked={hasMobile}
                        onChange={() => {
                          setHasMobile(!hasMobile);
                        }}
                      />{" "}
                      <span style={{ fontSize: "14px" }}>{t("admin.has_mobile")}</span>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        style={{ marginBottom: "30px" }}
                        checked={shouldReceiveReport}
                        onChange={() => {
                          setShouldReceiveReport(!shouldReceiveReport);
                        }}
                      />{" "}
                      <span style={{ fontSize: "14px" }}>
                        {t("admin.receives_periodic_reports")}
                      </span>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        style={{ marginBottom: "30px" }}
                        checked={mustChangePassword}
                        onChange={() => {
                          setMustChangePassword(!mustChangePassword);
                        }}
                      />{" "}
                      <span style={{ fontSize: "14px" }}>{t("admin.must_change_password")}</span>
                    </div>
                    <input
                      type="checkbox"
                      style={{ marginBottom: "30px" }}
                      value={sendEmail}
                      onChange={() => {
                        setSendEmail(!sendEmail);
                      }}
                    />{" "}
                    <span style={{ fontSize: "14px" }}>{t("admin.send_welcome_email")}</span>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox px={5} pb={5}>
              <IconButton
                onClick={() => {
                  if (email && validateEmail(email)) {
                    setIsEmailValid(true);
                    if (name && email && password && validateEmail(email)) {
                      const userToCreate = {
                        name,
                        email,
                        password,
                        role,
                        clients: clientsSelected,
                        sendEmail,
                        shouldReceiveReport,
                        mustChangePassword,
                        hasMobile,
                      };
                      createUser(userToCreate)
                        .then((res) => {
                          if (res) {
                            const logToSend = {
                              email: user && user.email ? user.email : "",
                              type: `Created new user ${email}`,
                              date: new Date(),
                            };
                            addLog(logToSend);
                            setName("");
                            setEmail("");
                            setPassword("");
                            setErrorMessage("");
                            setClient("");
                            setClients(originalClients);
                            setElementInList(0);
                            setShouldReceiveReport(false);
                            setHasMobile(false);
                            setPopup(true);
                          } else {
                            setErrorMessage(t("admin.error_create_user_text"));
                          }
                        })
                        .catch((err) => {
                          // eslint-disable-next-line
                          console.log("errore creazione utente", err);
                          setErrorMessage(t("admin.error_create_user_text"));
                        });
                    }
                  } else if (email && !validateEmail(email)) {
                    setIsEmailValid(false);
                  }
                }}
                size="small"
                disableRipple
              >
                {t("admin.add")}
              </IconButton>
            </MDBox>
            {errorMessage ? (
              <MDBox px={5} pb={5}>
                <MDTypography fontSize="medium" color="error">
                  {errorMessage}
                </MDTypography>
              </MDBox>
            ) : null}
            {email && !isEmailValid ? (
              <MDBox px={5} pb={5}>
                <MDTypography fontSize="medium" color="error">
                  {t("admin.invalid_email")}
                </MDTypography>
              </MDBox>
            ) : null}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AddUser;
